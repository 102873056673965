import {FunctionComponent} from "react";
import MissionImage1 from "../partials/images/mission-5.webp";
import MissionImage2 from "../partials/images/mission-2.webp";
import MissionImage3 from "../partials/images/mission-3.webp";
import MissionImage4 from "../partials/images/mission-4.webp";

interface Props {
  recipeCount: number
  ingredientCount: number
  tagCount: number
  tipCount: number
}

const MissionStatement: FunctionComponent<Props> = (props: Props) => {
  const {
    recipeCount,
    ingredientCount,
    tagCount,
    tipCount
  } = props
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <div className="max-w-4xl">
          <p className="text-base/7 font-semibold text-emerald-600">My Mission</p>
          <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            On a mission to empower home cooks
          </h1>
          <p className="mt-6 text-balance text-xl/8 text-gray-700">
            Cooking at home should be joyful, not overwhelming. I created Pantry Savvy to make meal planning, budgeting,
            and cooking easier for busy families like mine. Whether you're looking for quick dinners, creative ways to
            use what’s in your pantry, or just a little kitchen inspiration, I’m here to help—because great meals start
            with simple, smart solutions.
          </p>
        </div>
        <section className="mt-20 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-16">
          <div className="lg:pr-8">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-gray-900">My mission</h2>
            <p className="mt-6 text-base/7 text-gray-600">
              Hi, I’m Javy, the creator of Pantry Savvy! I originally built this site for <i>my own family</i>—to make
              it easier to plan meals, use what we have, and cook delicious, budget-friendly food without the stress.
              What started as a personal solution quickly turned into something bigger, and now I love sharing it
              with <i>you</i>.
            </p>
            <p className="mt-8 text-base/7 text-gray-600">
              Cooking at home has always been important to me, but like many busy families, we struggled with meal
              planning, food waste, and figuring out what to make next. Pantry Savvy became my way of simplifying the
              process. Now, even my kids love searching for recipes and getting involved in the kitchen—it’s become a
              fun part of our daily lives!
            </p>

            <p className="mt-8 text-base/7 text-gray-600">
              I know firsthand how challenging it can be to juggle ingredients, time, and budget, and I also know the
              joy that comes from a home-cooked meal. That’s why I’m always working to make Pantry Savvy better—not just
              for me, but for you, too. If there’s something that would help make your cooking experience easier, I’d
              love to hear from you! Your feedback helps shape this site and keeps it growing in ways that truly make a
              difference.
            </p>
            <p className="mt-8 text-base/7 text-gray-600">
              Let’s make the kitchen a place of creativity, confidence, and good food—together!
            </p>
          </div>
          <div className="pt-16 lg:row-span-2 lg:-mr-16 xl:mr-auto">
            <div
              className="-mx-8 grid grid-cols-2 gap-4 sm:-mx-16 sm:grid-cols-4 lg:mx-0 lg:grid-cols-2 lg:gap-4 xl:gap-8">
              <div
                className="aspect-square overflow-hidden rounded-xl shadow-xl outline outline-1 -outline-offset-1 outline-black/10">
                <img
                  alt="Plan your meals"
                  src={MissionImage1}
                  className="block size-full object-cover"
                />
              </div>
              <div
                className="-mt-8 aspect-square overflow-hidden rounded-xl shadow-xl outline outline-1 -outline-offset-1 outline-black/10 lg:-mt-40">
                <img
                  alt=""
                  src={MissionImage2}
                  className="block size-full object-cover"
                />
              </div>
              <div
                className="aspect-square overflow-hidden rounded-xl shadow-xl outline outline-1 -outline-offset-1 outline-black/10">
                <img
                  alt=""
                  src={MissionImage3}
                  className="block size-full object-cover"
                />
              </div>
              <div
                className="-mt-8 aspect-square overflow-hidden rounded-xl shadow-xl outline outline-1 -outline-offset-1 outline-black/10 lg:-mt-40">
                <img
                  alt=""
                  src={MissionImage4}
                  className="block size-full object-cover"
                />
              </div>
            </div>
          </div>
          <div className="max-lg:mt-16 lg:col-span-1">
            <p className="text-base/7 font-semibold text-gray-500">The numbers</p>
            <hr className="mt-6 border-t border-gray-200"/>
            <dl className="mt-6 grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
              <div className="flex flex-col gap-y-2 border-b border-dotted border-gray-200 pb-4">
                <dt className="text-sm/6 text-gray-600">Recipes</dt>
                <dd className="order-first text-6xl font-semibold tracking-tight">
                  <span>{recipeCount}</span>
                </dd>
              </div>
              <div className="flex flex-col gap-y-2 border-b border-dotted border-gray-200 pb-4">
                <dt className="text-sm/6 text-gray-600">Ingredients</dt>
                <dd className="order-first text-6xl font-semibold tracking-tight">
                  <span>{ingredientCount}</span>
                </dd>
              </div>
              <div
                className="flex flex-col gap-y-2 max-sm:border-b max-sm:border-dotted max-sm:border-gray-200 max-sm:pb-4">
                <dt className="text-sm/6 text-gray-600">Tags</dt>
                <dd className="order-first text-6xl font-semibold tracking-tight">
                  <span>{tagCount}</span>
                </dd>
              </div>
              <div className="flex flex-col gap-y-2">
                <dt className="text-sm/6 text-gray-600">Tips</dt>
                <dd className="order-first text-6xl font-semibold tracking-tight">
                  <span>{tipCount}</span>
                </dd>
              </div>
            </dl>
          </div>
        </section>
      </div>
    </div>
  )
}

export default MissionStatement
